<template>
  <v-menu :value="isOpen" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-badge
        overlap
        :value="activeFilters.length > 0"
        :content="activeFilters.length">
        <v-btn v-on="on" @click="isOpen = true" class="text-capitalize">
          <v-icon left>mdi-filter-outline</v-icon>
          Filters
        </v-btn>
      </v-badge>
    </template>
    <v-card class="py-4 fill" width="350">
      <div v-for="filter in filters" :key="filter.key">
        <v-list-item>
          <v-autocomplete
            v-if="filter.type == 'select'"
            :items="filter.options"
            :label="filter.name"
            :menu-props="{ maxHeight: 225 }"
            @change="handleInputValue(filter)"
            @click:clear="removeFilter(filter.key)"
            auto-select-first
            background-color="input"
            class="pl-3"
            clearable
            dense
            hide-details
            item-text="description"
            item-value="id"
            outlined
            v-model="params[`${filter.key}`]">
            <template v-slot:item="data">
              <v-list-item-content style="max-width: 275px">
                <div class="wrap-text subtitle-2">
                  {{ data.item.description }}
                </div>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-checkbox
            @change="handleInputValue(filter)"
            class="pl-3 pt-0 my-0"
            :label="filter.name"
            v-if="filter.type === 'boolean'"
            hide-details
            v-model="params[`${filter.key}`]">
          </v-checkbox>
        </v-list-item>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'SearchFilters',
  props: {
    ad: Object,
    departments: Array,
    pages: Array,
    featureTypes: Array,
    featureTexts: Array,
    isShipper: Boolean,
    quantityFilter: Boolean,
    clearFilters: Boolean
  },
  data() {
    return {
      isOpen: false,
      selectedFilters: [],
      params: {
        page: this.page,
        feature_text: this.featureText,
        feature_type: this.featurePosition,
        department: this.departments,
        is_shipper: this.isShipper
        // item_status: this.itemStatus
      }
    }
  },
  computed: {
    activeFilters() {
      return Object.keys(this.params).filter(this.isActive)
    },
    filters() {
      return [
        { name: 'Page', key: 'page', options: this.pages, type: 'select' },
        {
          name: 'Feature Type',
          key: 'feature_type',
          options: this.featureTypes,
          type: 'select'
        },
        {
          name: 'Feature Text',
          key: 'feature_text',
          options: this.featureTexts,
          type: 'select'
        },
        {
          name: 'Departments',
          key: 'department',
          options: this.departments,
          type: 'select'
        },
        // {
        //   name: 'Item Status',
        //   key: 'item_status',
        //   options: ['Active', 'Discontinued'],
        //   type: 'select'
        // },
        {
          name: 'Shipper | Mod',
          key: 'components_only',
          options: this.isShipper,
          type: 'boolean'
        },
        {
          name: 'Items With Orders',
          key: 'quantity_filter',
          options: this.quantityFilter,
          type: 'boolean'
        }
      ]
    }
  },
  watch: {
    ad: {
      handler(newValue, oldValue) {
        if (newValue) {
          if (oldValue.id !== newValue.id) {
            this.params = {}
          }
        }
      }
    },
    activeFilters: {
      handler: debounce(function (newValue) {
        if (newValue) {
          this.updateSearchFilters()
        }
      }, 400),
      deep: true
    }
  },
  created() {
    if (sessionStorage.search_filters) {
      const stored = JSON.parse(sessionStorage.search_filters)
      this.params = { ...this.params, ...stored }
      const activeKeys = Object.keys(stored).filter(key => Boolean(stored[key]))
      if (activeKeys.length > 0) this.selectedFilters.push(...activeKeys)
    }
  },
  methods: {
    isActive(key) {
      if (this.isSelected(key) && Boolean(this.params[key])) {
        const value = this.params[key]
        return Array.isArray(value) ? value.length > 0 : true
      }
      return false
    },
    getFilterText(item) {
      if (item.key === 'components_only') {
        return item.name
      } else {
        return `${item.name}: "${this.params[item.key]}"`
      }
    },
    removeFilter(key, clearValue = false) {
      const index = this.selectedFilters.indexOf(key)
      if (index > -1) {
        this.selectedFilters.splice(index, 1)
        if (clearValue) this.params[key] = null
      }
    },
    handleInputValue: debounce(function (item) {
      console.log(item)
      if (item.type === 'boolean' && !this.params[item.key]) {
        this.removeFilter(item.key, true)
      }
      if (Boolean(this.params[item.key]) && !this.isSelected(item.key)) {
        console.log(Boolean(this.params[item.key]), !this.isSelected(item.key))
        this.selectedFilters.push(item.key)
      }
    }, 200),
    isSelected(key) {
      return this.selectedFilters.includes(key)
    },
    updateSearchFilters() {
      const filtersObj = this.activeFilters.reduce((obj, key) => {
        obj[key] = this.params[key]
        return obj
      }, {})
      this.isOpen = false
      this.$emit('input', filtersObj)
      if (this.activeFilters.length > 0) {
        sessionStorage.setItem('search_filters', JSON.stringify(filtersObj))
      } else {
        sessionStorage.removeItem('search_filters')
      }
    }
  }
}
</script>
<style scoped>
.wrap-text {
  overflow-wrap: break-word;
}
</style>
